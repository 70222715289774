module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Priyank Vaghela - Links","short_name":"Priyank Vaghela","start_url":"/","background_color":"#295bb2","theme_color":"#3b82ff","display":"fullscreen","icon":"content/alibabacloud_priyankvaghela-circle-shadow-v2.png","icon_options":{"purpose":"maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a067e9acd82e49c76d9624f2f9bb3fd9"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1540,"linkImagesToOriginal":false,"showCaptions":true,"quality":80,"withWebp":true,"tracedSVG":true,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"Priyank Vaghela - Links: CMS","htmlFavicon":"content/alibabacloud_priyankvaghela-circle-shadow-v2.png","modulePath":"/opt/build/repo/node_modules/@marscollective/gatsby-theme-link-free/src/cms","manualInit":true},
    }]
